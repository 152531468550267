<template>
  <div id="deals" class="deals-component">
    <div class="posts">
      <div class="deal-container deal-scroll">
        <a :href="getDealLink(deal)" v-for="deal in deals"  :key="deal._id">
          <div class="card">

            <div class="discount"><a :href="getDealLink(deal)"><div class="dealImg" v-if="deal.photos && deal.photos[0]"><img :src="'https://sugar.ng/public/images/resize_300/' + deal.photos[0]"></div> {{deal.percent}}% <span>OFF</span></a></div>
            
            <div class="caption">{{deal.title}}</div>
            <div class="vendor">{{deal.listing.name}}</div>
          </div>
        </a>
      </div>
      <!-- <div :class="'view-more ' + className"><a href="/promos/1" >View More Deals &raquo;</a></div> -->
      <div class="view-more"><a class="btn btn-outline-primary" href="https://sugar.ng/promos/1">View More Deals &raquo;</a></div>
    </div>
    
    
  </div>
</template>

<script>

  import { RepositoryFactory } from "@/repositories/RepositoryFactory"  
  const DealsRepository = RepositoryFactory.get('deals')

export default {
  props: {
    count: {
      type: Number,
      default: 4
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return{
      deals: '',
    }
    
  },
  methods:{
    async getDeals(limit, page){
        const { data } = await DealsRepository.getDeals(limit, page, 'active')
        if (data.success){
          this.deals = data.data
        } 

    },

    getDealLink(deal){
      return 'https://sugar.ng/promo/'+deal._id
    }


  },
  mounted(){
    this.getDeals(this.count, 1)
  },

}
</script>

<style scoped>


  .deals-component{
    background:#fff;
    padding:20px 20px 55px 20px;
  }

  .btn-outline-primary{
    border-color:#EA208F;
    color:#EA208F;
    margin-top:30px;
  }

  .btn-outline-primary:hover{
    background: linear-gradient(to right, #EA208F, #F7924B);
    color:#FFF;
    border-color:#FFF;
  }

  .view-more{
    text-align:center;
  }


  h4{
    text-transform:uppercase;
    color:#78184A;
    margin-top:35px;
    margin-bottom:25px;
  }

  a:hover{
    text-decoration:none;
  }

  a{
    color:#333;
  }

  .posts .deal-container{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:30px;
  }



  .card{
    background:rgba(204,204,204,0.3);
    box-shadow:3px 3px 5px #CCC;
    border-width:0px;
    color:#333;
    text-align:left;
    padding:15px;

  }

  a:hover .card{
     background: linear-gradient(to right, #EA208F, #F7924B);
     color:#FFF;
  }

  a:hover .card a{
     color:#FFF;
  }

  .card .discount{
    font-size:35px;
    margin-top:15px;
  }

  .card .discount span{
    font-size:60%;
  }

  .card .caption{
    height:100px;
    
  }

  .card .dealImg{
    height:80px;
    width:80px;
    overflow:hidden;
    float:right;
    border-radius:50%;
    margin-top:-15px;
  }

  .card .dealImg img{
    max-width:100px;
    min-height:100px;
  }

  .card .vendor{
    text-transform:uppercase;
    font-family:"Gill Sans W01";
    
  }

  .view-more.grey a{
    color:#EA208F;
  }

  @media (max-width: 768px){
    .posts .deal-container{
      grid-template-columns: 1fr;
    }
  }

  
</style>
