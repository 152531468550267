import axios from 'axios';
import NProgress from 'nprogress';


// const baseDomain = process.env.VUE_APP_DOMAIN;
const baseURL = 'https://sugar.ng/api';

const instance = axios.create({
	baseURL
})

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  NProgress.start()
  return config
})

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
  NProgress.done()
  return response
},
function (error) {
  NProgress.done()
  return error.response
})

export default instance
