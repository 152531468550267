import Repository from "./Repository";

const resource = "/listings";

export default {
	getByCategory (categoryId, lat, lon, page, limit){
		return Repository.get(`${resource}/view/category/${categoryId}/${lat}/${lon}/${page}/${limit}`);
	},
	getById (slug){
		// console.log('in repository')
		return Repository.get(`${resource}/view/${slug}`);
	}, 
	getVendorByAlbum(aId){
		return Repository.get(`${resource}/view/by/album/${aId}`);
	},
	
	// getAllAlbums (){
	// 	return Repository.get('/albums/tempview');
	// },

	getAlbumById(id){
		return Repository.get(`/albums/view/${id}`);
	},

	getUntaggedPhotos(uid, limit, page){
		return Repository.get(`/albums/photos/untagged/${uid}/${limit}/${page}`);
	},

	getAlbumByCategories(){
		return Repository.get(`/albums/categories/view`);
	},

	tagAlbumPhoto(paramObj){
		return Repository.post('/albums/tag/photo', paramObj);
	},

	getPhotosByTag(slug, page, limit){
		return Repository.get(`/albums/photos/tag/${slug}/${page}/${limit}`);
	},

	getAlbumCategory(slug){
		return Repository.get(`/albums/albumcategory/${slug}`);
	}, 

	addListing(formData){
		return Repository.post(`${resource}/add`, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
    	})
	},

	deleteListing(lid){
		return Repository.delete(`/listings/delete/${lid}`);
	},

	editListing(formData){
		return Repository.post(`${resource}/edit`, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
    	})
	},

	getMyListings(userId, limit, page){
		// console.log('in repository')
		return Repository.get(`${resource}/view/owner/${userId}/${limit}/${page}`);
	},

	getMyAlbums(userId, limit, page){
		// console.log('in repository')
		return Repository.get(`/albums/owner/${userId}/${limit}/${page}`);
	},

	fetchAlbum(albumId){
		return Repository.get(`/albums/view/${albumId}`);
	},

	addAlbum(name, listing, owner){
		return Repository.post(`/albums/add`, {
			name: name,
			owner: owner,
			listing:listing
		})
	},

	uploadAlbumImages(albumId, formData){
		return Repository.post(`/albums/image/${albumId}`, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
    	})
	},

	deleteAlbumImage (albumId, photoId){
		//router.delete('/image/:albumId/:photoId'
		return Repository.delete(`/albums/image/${albumId}/${photoId}`)
	},


	editAlbum(albumId, albumData){
		return Repository.patch(`/albums/edit/${albumId}`, albumData)
	},

	deleteAlbum(aid){
		return Repository.delete(`/albums/delete/${aid}`);
	},

	searchListing(userId, query, page, limit){
		return Repository.get(`${resource}/search/${query}/${limit}/${page}/${userId}`)
	},


	getAllListings(userId, page, limit){
		return Repository.get(`${resource}/all/${userId}/${limit}/${page}`)
	},




};