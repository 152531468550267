<template>
  <div id="app">
    <DealsSteals></DealsSteals>
  </div>
</template>

<script>
import DealsSteals from "./components/DealsSteals.vue";

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: "app",
  components: {
    DealsSteals
  }
};
</script>

<style>
 a{
  text-decoration:none;
 }

 body{
  font-family:"Gill Sans W01";
 }

 .card{
  border-radius:5px;
 }

 .btn{
  padding:10px;
  border-width:1px;
  margin-top:50px;
  border-radius:5px;
  display:inline-block;
 }
</style>
