import Vue from "vue"
import App from "./App.vue"

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
// import BootstrapVue from 'bootstrap-vue'


Vue.use(vueCustomElement)

// Vue.use(BootstrapVue)

Vue.customElement('deals-widget', App)
