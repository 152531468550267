import CategoriesRepository from "./categoriesRepository";
import ListingsRepository from "./listingsRepository";
import DealsRepository from "./dealsRepository";

const repositories = {
	categories: CategoriesRepository,
	listings: ListingsRepository,
	deals: DealsRepository
};

export const RepositoryFactory = {
	get: name => repositories[name]
}