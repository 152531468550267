import Repository from "./Repository";


export default {
	getDeals(limit, page, status = 'all'){
		// console.log('request', `/deals/${status}/${limit}/${page}`)
		return Repository.get(`/deals/random/${status}/${limit}/${page}`);
	},

	getDealById(id){
		return Repository.get(`/deals/view/${id}`)
	},

	getMyDeals(userId, limit, page){
		return Repository.get(`/deals/owner/${userId}/${limit}/${page}`)
	},

	addDeal(formData, userId){
		return Repository.post(`/deals/add/${userId}`, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
    	})
	},

	editDeal(formData, dealId){
		return Repository.patch(`/deals/edit/${dealId}`, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }
    	})
	},

	approveDeal(dealId, userId){
		return Repository.put(`/deals/approve/${dealId}/${userId}`)
	},

	unApproveDeal(dealId, userId){
		return Repository.put(`/deals/unapprove/${dealId}/${userId}`)
	}
};